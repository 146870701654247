/*
  We've already included normalize.css.

  But we'd like a modern looking boilerplate.
  Clean type, sans-serif, and a nice color palette.

*/

body {
  font-family: system-ui, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

#app {
  padding: 1rem;
}

button {
  appearance: none;
  border: 0px;
  width: 100%;
  aspect-ratio: 2 / 1;
  border-radius: 32px;
  font-size: 128px;
  display: grid;
  place-content: center;
  transition: transform 0.125s ease-in-out;
  box-shadow:
    8px 8px 8px 0px rgba(255, 255, 255, 0.2) inset,
    -8px -8px 8px 0px rgba(0, 0, 0, 0.05) inset;
}
button:hover {
  transform: translateY(-2px) scale(1.03125);
}
button:active {
  transform: translateY(0) scale(0.96875);
}
